export const APPVERSION = 1;

export const FOOD_TYPES = [
  { name: 'Breakfast', value: 'breakfast' },
  { name: 'Lunch', value: 'lunch' },
  { name: 'Dinner', value: 'dinner' },
  { name: 'Snacks', value: 'snacks' },
];

export const NUM_DAY_OPTIONS = [
  { value: 2, min: 'Mon', max: 'Tue' },
  { value: 3, min: 'Mon', max: 'Wed' },
  { value: 5, min: 'Mon', max: 'Fri' },
  // { value: 7, min: 'Mon', max: 'Sun' },
];

export const NUM_DAY_OFFSETS = {
  CARRIER: 0,
  SHIPPING: 0,
};

export const DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const PROGRAM_FOOD_OPTIONS = [
  { id: '1', name: 'Lunch, Dinner, Snack, Probiotic Tonics, Ceremonial Matcha (No Breakfast)', default: true, position: 1, tags: ['5 Days'] },
  { id: '2', name: 'Breakfast, Lunch, Dinner, Snack, Probiotic Tonics, Ceremonial Matcha', position: 2, tags: ['4 Days', '3 Days', '2 Days', '1 Day'] },
];

const save10 = ' - save 10%';

export const PROGRAM_LENGTHS = [
  { name: '1 Week', value: '1', default: false },
  { name: '2 Weeks', value: '2', default: false },
  { name: '3 Weeks' + save10, value: '3', default: true },
  { name: '4 Weeks' + save10, value: '4', default: false },
  { name: '6 Weeks' + save10, value: '6', default: false },
  { name: '8 Weeks' + save10, value: '8', default: false },
];

export const FOODS_TO_AVOID_NUM_TAGS_FOR_WARNING = 4;

export const ESTIMATE_MIN_AMOUNT = 100;
export const ESTIMATE_ERROR_AMOUNT = 2000;

// export const DEFAULT_PROMO_CODE = { code: 'WELCOME10', amount: 10, unit: '%' }; // FIXME: Temp Disabled default promo code
export const DEFAULT_PROMO_CODE = {};

export const PROGRAM_SCHEDULE_VALUES = {
  COURIER: 'courier',
  SHIPPING: 'shipping',
  PRE_SUBSCRIBED: 'pre_subscribed',
};
