import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import withHookHoc from './WithHookHoc';

import { Button, ControlsGrid, FormGroup, Input, InputErrorMessage, InputSuccessMessage, Label } from 'core/ui/atoms';
import { APPLY_PROMO_CODE } from 'core/api/apollo/mutations';
import Tracker from 'core/api/tracking/tracker';

class PromoCodeInput extends PureComponent {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    code: PropTypes.string,
    partnerLink: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      partnerLink: props.partnerLink,
      promoCode: props.code,
      isProcessing: false,
      promoCodeError: '',
      promoCodeSuccess: false,
    };

    this.button = React.createRef();
  }

  componentDidMount() {
    this.button.current.click();
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  applyPromoCode = (e, applyPromoCode) => {
    e.preventDefault();
    const { promoCode, partnerLink } = this.state;
    const input = { promoCode, partnerLink };
    this.setState({ isProcessing: true });
    applyPromoCode({
      variables: {
        input,
      },
    }).catch(() => {
      this.setState({ isProcessing: false });
    });
  };

  onPromoCodeApplied = ({ applyPromoCode: { errors } }) => {
    const { onSuccess } = this.props;
    const { promoCode } = this.state;

    if (errors && errors.length) {
      this.setState({ isProcessing: false, promoCodeError: errors[0].message });
    } else {
      Tracker.trackApplyPromoCode({ promoCode });
      onSuccess(this.state.promoCode);
      this.setState({ isProcessing: false, promoCode: '', promoCodeSuccess: true, promoCodeError: false });
      this.promoCodeTimeout = setTimeout(() => {
        this.setState({ promoCodeSuccess: false });
      }, 5000);
    }
  };

  render() {
    const { promoCode, promoCodeError, promoCodeSuccess, isProcessing, partnerLink } = this.state;
    return (
      <Mutation
        mutation={APPLY_PROMO_CODE}
        variables={{ input: { promoCode, partnerLink: partnerLink } }}
        onCompleted={this.onPromoCodeApplied}
      >
        {(applyPromoCode) => (
          <PromoCodeGrid>
            <FormGroup>
              <Label>Promo Code</Label>
              <Input
                type="text"
                placeholder="Promo Code"
                name="promoCode"
                error={!!promoCodeError}
                onChange={this.onChange}
                value={promoCode}
              />
              {promoCodeError && <InputErrorMessage>{promoCodeError}</InputErrorMessage>}
              {promoCodeSuccess && <InputSuccessMessage>Great, your best promo code has been applied.</InputSuccessMessage>}
            </FormGroup>

            <FormGroup>
              <PromoCodeButton
                ref={this.button}
                btn-primary
                onClick={(e) => this.applyPromoCode(e, applyPromoCode)}
                disabled={!promoCode || isProcessing}
              >
                Apply
              </PromoCodeButton>
            </FormGroup>
          </PromoCodeGrid>
        )}
      </Mutation>
    );
  }
}

const PromoCodeGrid = styled(ControlsGrid)`
  position: relative;
  grid-template-columns: 2fr 1fr;
  column-gap: 1em;
  row-gap: 0;
`;


const PromoCodeButton = styled(Button)`
  display: inline-block;
  margin-top: 2em;
  width: 100%;
  border-radius: 2em;
  padding: 0.9em 2em;
`;

export default withHookHoc(PromoCodeInput);
