import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import GlobalStyles from 'core/ui/globalStyles';

import history from 'core/api/history';
import Tracker from 'core/api/tracking/tracker';
import theme from 'core/ui/theme';
import client from 'core/api/apollo/apolloClient';
import { storeSessionTestAssignments } from 'core/api/splitTestHelpers';
import { LogProvider, AccountProvider, ReferralCodeContextProvider } from 'core/api';
import { CURRENT_USER, GET_SPLIT_TEST_ASSIGNMENTS } from 'core/api/apollo/queries';
import MainRoutes from 'core/ui/layouts/MainRoutes';
import Rollbar from 'core/ui/components/Rollbar';
import Alerts from 'core/ui/components/Alerts';
import ErrorBoundary from 'core/ui/components/ErrorBoundary';
import ErrorOccurred from 'core/ui/components/ErrorOccurred';
import LoadingScreen from 'core/ui/components/LoadingScreen';
import { APPVERSION } from 'onramp/constants';

import logger from 'core/api/logger';
const log = logger();

const SPLIT_TESTS = [];

export class MainLayout extends Component {
  constructor(props) {
    super(props);
    storeSessionTestAssignments(window.location);
  }

  async componentDidMount() {
    this.mounted = true;

    Tracker.trackPageView();
    window.addEventListener('resize', this.handleResize);
  }


  setReorder = (value) => {
    this.setState({ reorder: !!value });
  };

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.handleResize);
  }

  setStateSafely = (...args) => this.mounted && this.setState(...args);

  refreshNewVersion = (data) => {
    if (!isEmpty(data) && !isEmpty(data.currentUser)) {
      const { appVersion } = data.currentUser;
      if (!isEmpty(appVersion) && appVersion > APPVERSION) {
        window.location.reload(true);
        // eslint-disable-next-line no-console
        console.log('A new version is available');
      }
    }
  }

  render() {
    return (
      <Router history={history}>
        <LogProvider value={log}>
          <ErrorBoundary>
            <ApolloProvider client={client}>
              <ThemeProvider theme={theme}>
                <ReferralCodeContextProvider>
                  <Query query={CURRENT_USER}>
                    {({ loading: userLoading, error, data = {} }) => {
                      this.refreshNewVersion(data);
                      if (error) {
                        return <ErrorOccurred error={error} />;
                      }

                      return (
                        <AccountProvider value={{ userLoading, currentUser: data.currentUser }}>
                          <Rollbar user={data.currentUser} />
                          <Alerts />
                          <Query
                            query={GET_SPLIT_TEST_ASSIGNMENTS}
                            variables={{ testNames: SPLIT_TESTS, readonly: true }}
                            skip={isEmpty(SPLIT_TESTS) || !data.currentUser}
                          >
                            {({ loading: splitTestsLoading }) => {
                              return splitTestsLoading ? <LoadingScreen /> : <MainRoutes />;
                            }}
                          </Query>
                        </AccountProvider>
                      );
                    }}
                  </Query>
                </ReferralCodeContextProvider>
                <GlobalStyles />
              </ThemeProvider>
            </ApolloProvider>
          </ErrorBoundary>
        </LogProvider>
      </Router>
    );
  }
}

export default MainLayout;
