import { gql } from '@apollo/client';

// price
export const PriceFragment = gql`
  fragment PriceFragment on Money {
    amount
    isoCode
  }
`;

// deliveryOption
export const DeliveryOptionFragment = gql`
  fragment DeliveryOptionFragment on DeliveryOption {
    id
    name
    position
    adminOnly
    isOvernight
    deliveryMethod
  }
`;

// address
export const AddressFragment = gql`
  fragment AddressFragment on Address {
    id
    street1
    street2
    city
    state
    zipCode
    deliveryOption {
      ...DeliveryOptionFragment
    }
    instructions
    supported
  }
  ${DeliveryOptionFragment}
`;

// billingAccount
export const BillingAccountBasicFragment = gql`
  fragment BillingAccountBasicFragment on BillingAccount {
    id
    active
    doNotCharge
  }
`;

export const BillingAccountFullFragment = gql`
  fragment BillingAccountFullFragment on BillingAccount {
    ...BillingAccountBasicFragment
    availableCredits {
      ...PriceFragment
    }
    brand
    last4
    expMonth
    expYear
  }
  ${BillingAccountBasicFragment}
  ${PriceFragment}
`;

// coupon & referralCoupon
export const CouponFragment = gql`
  fragment CouponFragment on Coupon {
    id
    code
    discountType
    discountPercentage
    durationType
    applicableOnWeek
    discountAmount {
      ...PriceFragment
    }
  }
  ${PriceFragment}
`;

export const ReferralCouponFragment = gql`
  fragment ReferralCouponFragment on ReferralCoupon {
    id
    code
    discountType
    discountPercentage
    discountAmount {
      ...PriceFragment
    }
    creditAmount {
      ...PriceFragment
    }
    referralUrl
  }
  ${PriceFragment}
`;

// deliveryDay
export const DeliveryDayFragment = gql`
  fragment DeliveryDayFragment on DeliveryDay {
    id
    date
    locked
    decOff
    cutoffTime
    closed
    closureReason
    subscribed
    subscriptionStatus
    menuPublished
    assigned
    order {
      id
    }
    period {
      id
      days {
        id
        date
      }
    }
  }
`;

// deliveryPeriod
export const DeliveryPeriodFragment = gql`
  fragment DeliveryPeriodFragment on DeliveryPeriod {
    id
    startDate
    endDate
    locked
    cutoffTime
    assigned
    days {
      ...DeliveryDayFragment
    }
  }
  ${DeliveryDayFragment}
`;

// ingredientTag
export const IngredientTagFragment = gql`
  fragment IngredientTagFragment on IngredientTag {
    id
    name
    userFacing
  }
`;

// diet
export const DietFragment = gql`
  fragment DietFragment on Diet {
    id
    name
    position
    ingredientTags {
      ...IngredientTagFragment
    }
  }
  ${IngredientTagFragment}
`;

// error
export const ErrorFragment = gql`
  fragment ErrorFragment on UserError {
    path
    message
  }
`;

// ingredient
export const IngredientFragment = gql`
  fragment IngredientFragment on Ingredient {
    id
    name
    displayName
    grams
  }
`;

// invoice
export const InvoiceBasicFragment = gql`
  fragment InvoiceBasicFragment on Invoice {
    id
    date
    refunded
    pastDue
    voided
    pdfUrl
    amount {
      ...PriceFragment
    }
    creditedAmount {
      ...PriceFragment
    }
  }
  ${PriceFragment}
`;

// marketingNotice
export const MarketingNoticeFragment = gql`
  fragment MarketingNoticeFragment on MarketingNotice {
    id
    headline
    url
    body
    priority
  }
`;

// marketingVideo
export const MarketingVideoFragment = gql`
  fragment MarketingVideoFragment on MarketingVideo {
    id
    videoId
    position
  }
`;

// program bundles and reservations
export const ProgramBundleFragment = gql`
  fragment ProgramBundleFragment on ProgramBundle {
    id
    name
    prepaidProgram {
      name
      description
    }
    schedule {
      name
      daysOfWeek {
        index
        name
      }
      deliveryDays {
        index
        name
      }
    }
    size {
      name
    }
    price {
      ...PriceFragment
    }
    availableDeliveryDays {
      ...DeliveryDayFragment
      dateWithoutOffset
    }
  }
  ${PriceFragment}
  ${DeliveryDayFragment}
`;

export const ProgramBundleReservationFragment = gql`
  fragment ProgramBundleReservationFragment on ProgramBundleReservation {
    id
    deliveryPeriod {
      ...DeliveryPeriodFragment
    }
    price {
      ...PriceFragment
    }
    description
  }
  ${DeliveryPeriodFragment}
`;

export const DeliveryReservationFragment = gql`
  fragment DeliveryReservationFragment on DeliveryReservation {
    id
    deliveryPeriod {
      ...DeliveryPeriodFragment
    }
    deliveryFee {
      ...PriceFragment
    }
    serviceFee {
      ...PriceFragment
    }
    fee {
      ...PriceFragment
    }
    description
  }
  ${DeliveryPeriodFragment}
`;

export const OneTimeProgramFragment = gql`
  fragment OneTimeProgramFragment on OneTimeProgram {
    id
    programBundle {
      ...ProgramBundleFragment
    }
    bundleReservations {
      ...ProgramBundleReservationFragment
    }
    invoiced
    unlocked
    progId
  }
  ${ProgramBundleFragment}
  ${ProgramBundleReservationFragment}
`;

export const PrepaidPurchaseFragment = gql`
  fragment PrepaidPurchaseFragment on PrepaidPurchase {
    deliveryPeriods {
      ...DeliveryPeriodFragment
    }
    bundleReservations {
      ...ProgramBundleReservationFragment
    }
    deliveryReservations {
      ...DeliveryReservationFragment
    }
    coupon {
      ...CouponFragment
    }
    discount {
      ...PriceFragment
    }
    total {
      ...PriceFragment
    }
  }
  ${DeliveryPeriodFragment}
  ${ProgramBundleReservationFragment}
  ${DeliveryReservationFragment}
  ${CouponFragment}
  ${PriceFragment}
`;

// productSize/calorieRange
export const ProductSizeFragment = gql`
  fragment ProductSizeFragment on ProductSize {
    id
    name
    description
    position
    minCalories
    maxCalories
    serves
    subscribable
  }
`;

// product tags
export const ProductTagGroupFragment = gql`
  fragment ProductTagGroupFragment on ProductTagGroup {
    id
    name
    position
    featured
  }
`;

export const ProductTagFragment = gql`
  fragment ProductTagFragment on ProductTag {
    id
    name
    position
    promo
    zone
    tagGroup {
      ...ProductTagGroupFragment
    }
  }
  ${ProductTagGroupFragment}
`;

// motivation
export const MotivationFragment = gql`
  fragment MotivationFragment on Motivation {
    id
    name
    description
    position
  }
`;

// notificationPreference
export const NotificationPreferencesFragment = gql`
  fragment NotificationPreferencesFragment on NotificationSettings {
    id
    receiveMarketingEmails
    receiveNewMenuReadyEmails
    receiveNewMenuReadySms
    receiveMenuReminderEmails
    receiveMenuReminderSms
    receiveRateFoodEmails
    receiveWellnessNewsletterEmails
    receiveWellnessNewsletterSms
    receiveDeliverySms
  }
`;

// nutritionFacts
export const NutritionFactsFragment = gql`
  fragment NutritionFactsFragment on NutritionFacts {
    calories
    carbsGrams
    fatGrams
    fiberGrams
    proteinGrams
    saturatedFatGrams
    sugarAlcoholGrams
    sugarGrams
  }
`;

export const ComponentNutritionFactsFragment = gql`
  fragment ComponentNutritionFactsFragment on ComponentNutritionFacts {
    calories
    carbsGrams
    fatGrams
    fiberGrams
    proteinGrams
    saturatedFatGrams
    sugarAlcoholGrams
    sugarGrams
  }
`;

// order
export const ProductVariantComponentFragment = gql`
  fragment ProductVariantComponentFragment on ProductVariantComponent {
    id
    name
    subtitle
    tagLine
    quantity
    shelfLife
    numServings
    weightOunces
    instructions
    ingredients {
      ...IngredientFragment
    }
    nutrition {
      ...ComponentNutritionFactsFragment
    }
  }
  ${IngredientFragment}
  ${ComponentNutritionFactsFragment}
`;

export const VariantBasicFragment = gql`
  fragment VariantBasicFragment on ProductVariant {
    id
    ingredientTags {
      ...IngredientTagFragment
    }
    numServings
    size {
      ...ProductSizeFragment
    }
    price {
      ...PriceFragment
    }
    weightOunces
  }
  ${IngredientTagFragment}
  ${ProductSizeFragment}
  ${PriceFragment}
`;

export const VariantFullFragment = gql`
  fragment VariantFullFragment on ProductVariant {
    ...VariantBasicFragment
    ingredients {
      ...IngredientFragment
    }
    nutrition {
      ...NutritionFactsFragment
    }
    components {
      ...ProductVariantComponentFragment
    }
  }
  ${VariantBasicFragment}
  ${IngredientFragment}
  ${NutritionFactsFragment}
  ${ProductSizeFragment}
  ${ProductVariantComponentFragment}
`;

// productCategory
export const ProductCategoryFragment = gql`
  fragment ProductCategoryFragment on ProductCategory {
    id
    name
    position
  }
`;

// productSubcategory
export const ProductSubcategoryFragment = gql`
  fragment ProductSubcategoryFragment on ProductSubcategory {
    id
    name
    position
    category {
      ...ProductCategoryFragment
    }
  }
  ${ProductCategoryFragment}
`;

// productLine
export const ProductLineFragment = gql`
  fragment ProductLineFragment on ProductLine {
    id
    name
    position
    subcategory {
      ...ProductSubcategoryFragment
    }
  }
  ${ProductSubcategoryFragment}
`;

// product diet tag
export const DietTagFragment = gql`
  fragment DietTagFragment on MealTag {
    id
    abbreviation
    name
    position
    userFacing
    actsAsFilter
  }
`;

// product image
export const ProductImageFragment = gql`
  fragment ProductImageFragment on ProductImage {
    id
    smallUrl
    largeUrl
    position
    externalVideoUrl
    videoId
  }
`;

export const ProductPreviewFragment = gql`
  fragment ProductPreviewFragment on Product {
    id
    name
    subtitle
    tagLine
    line {
      ...ProductLineFragment
    }
    primaryImage {
      ...ProductImageFragment
    }
    tags {
      id
    }
  }
  ${ProductLineFragment}
  ${ProductImageFragment}
`;

export const ProductDetailFragment = gql`
  fragment ProductDetailFragment on Product {
    id
    variants {
      ...VariantBasicFragment
    }
  }
  ${VariantBasicFragment}
`;

export const ProductBasicFragment = gql`
  fragment ProductBasicFragment on Product {
    ...ProductPreviewFragment
    ...ProductDetailFragment
  }
  ${ProductPreviewFragment}
  ${ProductDetailFragment}
`;

export const ProductFullFragment = gql`
  fragment ProductFullFragment on Product {
    ...ProductBasicFragment
    description
    instructions
    allergenWarning
    shelfLife
    zipcodeCharges
    dietTags {
      ...DietTagFragment
    }
    secondaryImage {
      ...ProductImageFragment
    }
    carouselImages {
      ...ProductImageFragment
    }
    variants {
      ...VariantBasicFragment
    }
  }
  ${VariantBasicFragment}
  ${DietTagFragment}
  ${ProductBasicFragment}
  ${ProductImageFragment}
`;

export const OrderLineItemFragment = gql`
  fragment OrderLineItemFragment on OrderLineItem {
    id
    quantity
    price {
      ...PriceFragment
    }
    unitPrice {
      ...PriceFragment
    }
    gratis
    message
    variant {
      ...VariantBasicFragment
    }
    product {
      ...ProductBasicFragment
    }
  }
  ${PriceFragment}
  ${VariantBasicFragment}
  ${ProductBasicFragment}
`;

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    id
    billed
    date
    billingTime
    cutoffTime
    trackingUrl
    billedAmount {
      ...PriceFragment
    }
    estimatedAmount {
      ...PriceFragment
    }
    deliveryFeeAmount {
      ...PriceFragment
    }
    serviceFeeAmount {
      ...PriceFragment
    }
    deliveryOption {
      ...DeliveryOptionFragment
    }
    skipped
    cancelled
    locked
    deliveryDay {
      id
    }
    lineItems {
      ...OrderLineItemFragment
    }
    assignmentWarnings
  }
  ${PriceFragment}
  ${DeliveryOptionFragment}
  ${OrderLineItemFragment}
`;

// programs
export const PrepaidProgramFragment = gql`
  fragment PrepaidProgramFragment on PrepaidProgram {
    id
    name
    description
    position
    default
  }
`;

export const ProgramScheduleFragment = gql`
  fragment ProgramScheduleFragment on ProgramSchedule {
    id
    name
    daysOfWeek {
      name
      index
    }
    default
  }
`;

export const ProgramSizeFragment = gql`
  fragment ProgramSizeFragment on ProgramSize {
    id
    name
    calories
    position
    default
  }
`;

// rateable orders
export const RateableOrderFragment = gql`
  fragment RateableOrderFragment on Order {
    id
    date
    rated
    image {
      ...ProductImageFragment
    }
  }
  ${ProductImageFragment}
`;

export const SplitTestAssignmentFragment = gql`
  fragment SplitTestAssignmentFragment on SplitTestAssignment {
    id
    testName
    cohort
  }
`;

// subscriptionCategory
export const SubscriptionCategoryFragment = gql`
  fragment SubscriptionCategoryFragment on SubscriptionCategory {
    id
    name
    position
  }
`;

// subscriptionQuantity
export const SubscriptionQuantityFragment = gql`
  fragment SubscriptionQuantityFragment on SubscriptionQuantity {
    id
    dayOfWeek
    dayName
    quantity
    subscriptionCategory {
      ...SubscriptionCategoryFragment
    }
  }
  ${SubscriptionCategoryFragment}
`;

// subscription
// NOTE: we include __typename to help avoid a conflict with GQL's Subscription type
export const SubscriptionFragment = gql`
  fragment SubscriptionFragment on Subscription {
    id
    __typename
    activationDate
    cancellationDate
    status
    subsId
    productSize {
      ...ProductSizeFragment
    }
    setupPreference {
      id
    }
    subscriptionQuantities {
      ...SubscriptionQuantityFragment
    }
  }
  ${ProductSizeFragment}
  ${SubscriptionQuantityFragment}
`;

// subscriptionOptions
export const SubscriptionOptionsFragment = gql`
  fragment SubscriptionOptionsFragment on SubscriptionSetupPreference {
    id
    breakfast
    dinner
    lunch
    snacks
    numDays
    subscribedDays
    productSize {
      ...ProductSizeFragment
    }
    estimatedPrice {
      ...PriceFragment
    }
    startPeriod {
      id
      cutoffTime
    }
    startDay {
      id
      date
    }
  }
  ${ProductSizeFragment}
  ${PriceFragment}
`;

// taste preferences
export const TastePreferencesFragment = gql`
  fragment TastePreferencesFragment on User {
    id
    foodsToAvoid {
      ...IngredientTagFragment
    }
    allergyTags {
      ...IngredientTagFragment
    }
    spicyPreference
  }
  ${IngredientTagFragment}
`;

// user
export const UserFragment = gql`
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    gender
    phoneNumber
    reorder
    userAccountType
    appVersion
    ...TastePreferencesFragment
    diet {
      ...DietFragment
    }
    motivation {
      id
    }
    ageRange {
      id
    }
    primaryAddress {
      ...AddressFragment
    }
    subscription {
      ...SubscriptionFragment
    }
    billingAccount {
      ...BillingAccountBasicFragment
    }
    referralCoupon {
      ...ReferralCouponFragment
    }
    notificationSettings {
      ...NotificationPreferencesFragment
    }
    featureFlags {
      id
      name
      enabled
    }
    nextOneTimeProgram {
      ...OneTimeProgramFragment
    }
  }
  ${TastePreferencesFragment}
  ${DietFragment}
  ${IngredientTagFragment}
  ${AddressFragment}
  ${SubscriptionFragment}
  ${BillingAccountBasicFragment}
  ${ReferralCouponFragment}
  ${NotificationPreferencesFragment}
  ${OneTimeProgramFragment}
`;
